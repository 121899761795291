import { mapGetters } from 'vuex'
import { get, map } from 'lodash-es'

export const TSCommonMixin = {
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    role() {
      return get(this.currentUser, 'customData.role')
    },
    photos() {
      return this.getPhotos(this.fluxItem)
    },
  },
  methods: {
    getPhotos(item) {
      if (get(item, 'rawData.img_files')) {
        if (item.source === 'leboncoin') {
          return map(get(item, 'rawData.img_files'), (i) => {
            return `${process.env.VUE_APP_IMG_SERVER}/lbcimg/${i.path}`
          })
        } else if (item.source === 'seloger') {
          return map(get(item, 'rawData.img_files'), (i) => {
            return `${process.env.VUE_APP_IMG_SERVER}/selogerimg/${i.path}`
          })
        } else if (item.source === 'bellesdemeures') {
          return map(get(item, 'rawData.img_files'), (i) => {
            return `${process.env.VUE_APP_IMG_SERVER}/bellesdemeuresimg/${i.path}`
          })
        } else if (item.source === 'lefigaro') {
          return map(get(item, 'rawData.img_files'), (i) => {
            return `${process.env.VUE_APP_IMG_SERVER}/lefigaroimg/${i.path}`
          })
        } else if (item.source === 'proprietes_lefigaro') {
          return map(get(item, 'rawData.img_files'), (i) => {
            return `${process.env.VUE_APP_IMG_SERVER}/proprieteslefigaroimg/${i.path}`
          })
        } else if (item.source === 'bienici') {
          return map(get(item, 'rawData.img_files'), (i) => {
            return `${process.env.VUE_APP_IMG_SERVER}/bieniciimg/${i.path}`
          })
        }
      } else if (get(item, 'rawData.photos')){
        return map(item.rawData.photos, 'url')
      } else if (get(item, 'rawData.img')){
        return  get(item, 'rawData.img')
      }  else {
        return []
      }
    },
    getRole(role) {
      switch (role) {
        case 'ADMIN':
          return { color: 'red', icon: 'mdi-shield-check' }
        case 'VALIDATOR':
          return { color: 'primary', icon: 'mdi-account-check' }
        case 'OPERATOR':
          return { color: 'teal', icon: 'mdi-account-search' }
        default:
          break
      }
    },
  },
}
